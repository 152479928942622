const ozgeReferenceInfo = [
  // There are two files per game:
  // tileset which determines the position of the platforms
  // and gameObjects which determines the position of enemies, powerups
  // coins moving platforms bonus boxes checkpoints etc.

  // [///// TILESET ////] //
  // in tileset, you can make a platform like this:
  {
    // count = how many tiles in the platform
    count: 20,
    // left = distance from the left
    left: 0,
    // top = distance down from the top (you may use negative values, don't go much below 450, camera stops at 550, player dies at 1300)
    top: 450,
  },
  // ex:
  {
    count: 10,
    left: 100,
    top: 250,
  },

  // [///// GAMEOBJECTS ////] //
  {
    // x and y are the same as left and top above object must be in quotes ""
    x: 1500,
    y: 200,
    // this can be one of these values:
    // "coin",
    // "fire",
    // "heart",
    // "life",
    // "enemy-charge",
    // "checkpoint1",
    // "checkpoint2",
    // "levelExit"
    object: 'coin',
  },
  // ex:
  {
    x: 3350,
    y: 10,
    // this enemy will run at the player (obeying gravity) he will keep moving left until he is destroyed
    object: 'enemy-charge',
  },
  {
    x: 1400,
    y: 350,
    // this enemy will move back and forth between his max and min points
    // x here is his starting position
    object: 'enemy-patrol',
    // set the limits of his patrole
    min: 1400,
    max: 1700,
    // right determines if he should start out moving to the right or to the left from x
    right: true,
  },
  {
    x: 17550,
    y: 150,
    object: 'cannon',
    // How powerful the cannon is in terms of horizontal and vertical velocity (won't work well if adjusted right now)
    xVelocity: 30,
    yVelocity: 35,
  },
  // The next two must be paired, they declare a bonus box and the bonus inside
  {
    x: 900,
    y: 200,
    object: 'bonusBox',
    // what is inside the box
    bonus: 'boxCoin',
    // always set these this way, I adjust them dynamically in the code
    pushed: false,
    indexInFrame: null,
    count: 1,
  },
  {
    x: 900,
    // make y value here 30 less than "bonusBox" as I did in this example,
    // otherwise player might collect when he hits the bottom of the box
    y: 170,
    // should match bonus: other possible value is "boxPineapple"
    object: 'boxCoin',
    // pretty sure value is meaningless, leave it always as "coin" for now.
    value: 'coin',
    // how fast does is bounce up and out of the box (must bounce up or player will get it instantly)
    // probably best to leave these unless we want to adjust them everywhere so it's consistant.
    jumpSpeed: -11,
    sideBounce: 3.5,
    // active should always be false
    active: false,
  },
  // all moving platforms you want timed in sync must be inside these brackets
  {
    // the object is moving-platforms with an "s" must do it this way even if it's only one
    object: 'moving-platforms',
    // min of first platform, max of furthest platform
    min: 5200,
    max: 6900,
    // false and null always
    pushed: false,
    indexInFrame: null,
    // put all individual platforms inside platforms
    platforms: [
      {
        // platform's starting position within its patrole
        x: 5600,
        y: 350,
        // there are two types, this one the player sticks to, the other is slippery (see below)
        object: 'moving-platform',
        // how long the platform is (only 2 works right now, but I can easily make it adjustable)
        count: 2,
        // platforms limits of patrole
        min: 5200,
        max: 6000,
        // starts to the right or left?
        right: false,
      },
      {
        x: 6500,
        y: 350,
        // the other type = slippery = more difficult
        object: 'mossy-moving-platform',
        count: 2,
        min: 6100,
        max: 6900,
        right: true,
      },
    ],
  },
];

export const tileset1 = [
  {
    count: 30,
    left: 100,
    top: 450,
  },
  {
    count: 2,
    left: 400,
    top: 250,
  },
];

export const gameObjects1 = [
  {
    x: 900,
    y: 200,
    object: 'bonusBox',
    bonus: 'boxCoin',
    pushed: false,
    indexInFrame: null,
    count: 1,
  },
  {
    x: 900,
    y: 180,
    object: 'boxCoin',
    value: 'coin',
    jumpSpeed: -11,
    sideBounce: 3.5,
    active: false,
  },
];

export const tileset2 = [
  {
    count: 30,
    left: 100,
    top: 450,
  },
];

export const gameObjects2 = [
  {
    x: 900,
    y: 200,
    object: 'bonusBox',
    bonus: 'boxCoin',
    pushed: false,
    indexInFrame: null,
    count: 1,
  },
  {
    x: 900,
    y: 180,
    object: 'boxCoin',
    value: 'coin',
    jumpSpeed: -11,
    sideBounce: 3.5,
    active: false,
  },
];

export const tileset3 = [
  {
    count: 30,
    left: 100,
    top: 450,
  },
];

export const gameObjects3 = [
  {
    x: 900,
    y: 200,
    object: 'bonusBox',
    bonus: 'boxCoin',
    pushed: false,
    indexInFrame: null,
    count: 1,
  },
  {
    x: 900,
    y: 180,
    object: 'boxCoin',
    value: 'coin',
    jumpSpeed: -11,
    sideBounce: 3.5,
    active: false,
  },
];
