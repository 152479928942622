import React, { Component } from 'react';
import Tile from '../../public/images/tile.png';
import HeartFull from '../../public/images/heart-full.png';
import CoinFull from '../../public/images/coin-full.png';
import Checkpoint1 from '../../public/images/checkpoint-1.png';
import LevelExit from '../../public/images/level-exit.png';
import Enemy1 from '../../public/images/ghost1.png';
import Fire1 from '../../public/images/fire1.png';
import Life1 from '../../public/images/life1.png';
import BonusBox from '../../public/images/bonus-box.png';
import MovingPlatformLeft1 from '../../public/images/moving-platform-left-1.png';
import MovingPlatformRight1 from '../../public/images/moving-platform-right-1.png';
import MossyMovingPlatformLeft1 from '../../public/images/mossy-moving-platform-left-1.png';
import MossyMovingPlatformRight1 from '../../public/images/mossy-moving-platform-right-1.png';
import Cannon from '../../public/images/cannon.png';

// import Layout from "../components/layout"
import {
  tileset1, gameObjects1, tileset2, gameObjects2, tileset3, gameObjects3,
} from '../components/ozge-tilesets.js';

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id: this.props.id,
      level1Text: '',
      level2Text: '',
      level3Text: '',
      enemy: '',
      tileSet: [...tileset1],
      gameObjects: [...gameObjects1],
    };
    this.startGame = this.startGame.bind(this);
  }

  componentDidMount() {
    this.startGame();
  }

  startGame() {
    const [tile, heartFull, coinFull, checkpoint1, levelExit, enemy1, fire1, life1, bonusBox, movingPlatformLeft1, movingPlatformRight1, mossyMovingPlatformLeft1, mossyMovingPlatformRight1, cannon] = [new Image(), new Image(), new Image(), new Image(), new Image(), new Image(), new Image(), new Image(), new Image(), new Image(), new Image(), new Image(), new Image(), new Image()];
    const [src, src2, src3, src4, src5, src6, src7, src8, src9, src10, src11, src12, src13, src14] = [Tile, HeartFull, CoinFull, Checkpoint1, LevelExit, Enemy1, Fire1, Life1, BonusBox, MovingPlatformLeft1, MovingPlatformRight1, MossyMovingPlatformLeft1, MossyMovingPlatformRight1, Cannon];

    const { tileSet } = this.state;
    const { gameObjects } = this.state;

    /// Create Tileset
    const canvas = document.getElementById('pixopixa-custom-tileset');
    const ctx = canvas.getContext('2d');
    canvas.width = 30000;
    canvas.height = 1500;
    ctx.fillStyle = '#00BCD4';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    tile.onload = () => {
      // Draw All Platforms
      tileSet.forEach((platform) => {
        for (let i = 0; i < platform.count; i++) {
          ctx.drawImage(tile, platform.left + (i * 80), platform.top, 80, 80);
        }
      });
    };

    heartFull.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'heart') {
          ctx.drawImage(heartFull, gameObject.x, gameObject.y, 50, 50);
        }
      });
    };

    coinFull.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'coin') {
          ctx.drawImage(coinFull, gameObject.x, gameObject.y, 60, 60);
        }
      });
    };

    enemy1.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'enemy-charge' || gameObject.object === 'enemy-patrol') {
          ctx.drawImage(enemy1, gameObject.x, gameObject.y, 100, 100);
        }
      });
    };

    life1.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'life') {
          ctx.drawImage(life1, gameObject.x, gameObject.y, 50, 50);
        }
      });
    };

    fire1.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'fire') {
          ctx.drawImage(fire1, gameObject.x, gameObject.y, 80, 80);
        }
      });
    };

    bonusBox.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'bonusBox') {
          ctx.drawImage(bonusBox, gameObject.x, gameObject.y, 81, 81);
        }
      });
    };

    cannon.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'cannon') {
          ctx.drawImage(cannon, gameObject.x, gameObject.y, 250, 250);
        }
      });
    };

    checkpoint1.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'checkpoint1' || gameObject.object === 'checkpoint2') {
          ctx.drawImage(checkpoint1, gameObject.x, gameObject.y, 150, 150);
        }
      });
    };

    levelExit.onload = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'levelExit') {
          ctx.drawImage(levelExit, gameObject.x, gameObject.y, 150, 150);
        }
      });
    };

    let movingPlatformLoadCount = 0;
    const drawMovingPlatforms = () => {
      gameObjects.forEach((gameObject) => {
        if (gameObject.object === 'moving-platforms') {
          gameObject.forEach((platform) => {
            if (platform.object === 'moving-platform') {
              ctx.drawImage(movingPlatformLeft1, platform.x, platform.y, 80, 80);
              ctx.drawImage(movingPlatformRight1, platform.x + 80, platform.y, 80, 80);
            } else if (platform.object === 'mossy-moving-platform') {
              ctx.drawImage(mossyMovingPlatformLeft1, platform.x, platform.y, 80, 80);
              ctx.drawImage(mossyMovingPlatformRight1, platform.x + 80, platform.y, 80, 80);
            }
          });
        }
      });
    };
    movingPlatformLeft1.onload = () => {
      movingPlatformLoadCount += 1;
      if (movingPlatformLoadCount === 4) {
        drawMovingPlatforms();
      }
    };
    movingPlatformRight1.onload = () => {
      movingPlatformLoadCount += 1;
      if (movingPlatformLoadCount === 4) {
        drawMovingPlatforms();
      }
    };
    mossyMovingPlatformLeft1.onload = () => {
      movingPlatformLoadCount += 1;
      if (movingPlatformLoadCount === 4) {
        drawMovingPlatforms();
      }
    };
    mossyMovingPlatformRight1.onload = () => {
      movingPlatformLoadCount += 1;
      if (movingPlatformLoadCount === 4) {
        drawMovingPlatforms();
      }
    };

    tile.src = src;
    heartFull.src = src2;
    coinFull.src = src3;
    checkpoint1.src = src4;
    levelExit.src = src5;
    enemy1.src = src6;
    fire1.src = src7;
    life1.src = src8;
    bonusBox.src = src9;
    movingPlatformLeft1.src = src10;
    movingPlatformRight1.src = src11;
    mossyMovingPlatformLeft1.src = src12;
    mossyMovingPlatformRight1.src = src13;
    cannon.src = src14;
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: 'center', position: 'relative' }} id="canvas-container">
          <canvas id="pixopixa-custom-tileset" />
        </div>
        <button onClick={() => {
          this.setState({
            tileSet: [...tileset1],
            gameObjects: [...gameObjects1],
          }, () => {
            this.startGame();
          });
        }}
        >
          Level 1
        </button>
        <button onClick={() => {
          this.setState({
            tileSet: [...tileset2],
            gameObjects: [...gameObjects2],
          }, () => {
            this.startGame();
          });
        }}
        >
          Level 2
        </button>
        <button onClick={() => {
          this.setState({
            tileSet: [...tileset3],
            gameObjects: [...gameObjects3],
          }, () => {
            this.startGame();
          });
        }}
        >
          Level 3
        </button>
      </div>
    );
  }
}

export default Game;
